import axios from 'axios';
import * as path from './path';

const absolutePath = (_path: string) => {
    return path.HOST + _path;
}

const header = () => {
    const bearer = `Bearer ${localStorage.getItem('auth_token')}`;
    return {
        headers:{
            'Authorization': bearer,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }
}

export const media = (_path: any) => {
    console.log(path.HOST_MEDIA + _path);
    return path.HOST_MEDIA + _path;
}

export const login = (email: string, clave: string) => {
    return new Promise((resolve, reject) => {
        const _absolutePath = absolutePath(path.LOGIN);
        const body = {
            email,
            clave
        }
        axios.post(_absolutePath, body).then((res: any) => {
            resolve(res);
        }).catch((err: any) => {
            reject(err);
        })
    });
}

//export const listProcesses = (sector: number, abn: number, estado: number, page: number) => {
export const listProcesses = (ruc: string, abn: number, estado: number, page: number) => {    
    return new Promise((resolve, reject) => {
        const _absolutePath = absolutePath(path.LIST_PROCESSES);
        const _header = header();
        //axios.get(_absolutePath + '/' + sector + '/' + abn + '/' + estado + '/' + page, _header).then((res: any) => {
        axios.get(_absolutePath + '/' + ruc + '/' + abn + '/' + estado + '/' + page, _header).then((res: any) => {            
            resolve(res);
        }).catch((err: any) => {
            reject(err);
        })
    });
}

export const detailProcess = (id: number) => {
    return new Promise((resolve, reject) => {
        const _absolutePath = absolutePath(path.DETAIL_PROCESSES);
        const _header = header();
        const body = { id };
        axios.post(_absolutePath, body, _header).then((res: any) => {
            resolve(res);
        }).catch((err: any) => {
            reject(err);
        })
    });
}

export const listCriteria = () => {
    return new Promise((resolve, reject) => {
        const _absolutePath = absolutePath(path.LIST_CRITERIA);
        const _header = header();
        const body = undefined;
        axios.post(_absolutePath, body, _header).then((res: any) => {
            resolve(res);
        }).catch((err: any) => {
            reject(err);
        })
    });
}

export const recoverPassword = (email: string) => {
    return new Promise((resolve, reject) => {
        const _absolutePath = absolutePath(path.RECOVER_PASSWORD);
        const _header = header();
        axios.post(_absolutePath + '?email=' + email, _header).then((res: any) => {            
            resolve(res);
        }).catch((err: any) => {
            reject(err);
        })
    });
}

export const changePassword = (email: any, pass: any, newpass: any) => {
    return new Promise((resolve, reject) => {
        const _absolutePath = absolutePath(path.CHANGE_PASSWORD);
        const _header = header();
        const body = {
            email: email,
            password: pass,
            newpassword: newpass
        }
        axios.post(_absolutePath, body, _header).then((res: any) => {                        
            resolve(res);
        }).catch((err: any) => {
            reject(err);
        })
    });
}

export const logout = () => {
    return new Promise((resolve, reject) => {
        const _absolutePath = absolutePath(path.LOGOUT);
        const _header = header();
        const body = null
        axios.post(_absolutePath, body, _header).then((res: any) => {
            resolve(res);
        }).catch((err: any) => {
            reject(err);
        })
    });
}

