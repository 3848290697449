import React, { Component } from 'react';
import logo from '../../assets/img/logomagia.svg';
import './footer.css';

export class FooterComponent extends Component {
  render() {
    return (
      <footer className="footer-component">
        <p className="powered">Desarrollado por <a href="http://magia.digital" target="blank"><img src={logo} alt="Magia Digital" height="16" className="logoFooter" /></a></p>
      </footer>
    )
  }
}
