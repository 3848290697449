import * as React from "react";
import "./procesosActivosDetalle.css";
import "antd/dist/antd.css";
import { SidebarComponent } from "../SidebarComponent/SidebarComponent";
import HeaderComponent from "./../../components/header/header";
import * as webservice from "../api/api";
import { Spin, Icon } from "antd";
class ProcesosActivosDetalle extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      load: false
    };
  }

  newurl = (url: any) => {
    let spliturl = url.split("/");
    let newPathname = "";
    for (var i = 3; i < spliturl.length; i++) {
      newPathname += "/";
      newPathname += spliturl[i];
    }
    return newPathname;
  };

  datetimeHumanizer = (date: string) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "UTC"
    };
    const datetime = new Date(date).toLocaleString("es-ES", options);
    return datetime;
  };

  componentWillMount() {
    const { id } = this.props.match.params;
    webservice
      .detailProcess(id)
      .then((res: any) => {
        const { data } = res.data;
        this.setState({
          ...data,
          load: true
        });
        console.log(data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  render() {
    const { id } = this.props.match.params;
    const { cliente, load, pasos, desestimar, ofertas } = this.state;
    const antIcon = (
      <Icon type="loading" style={{ fontSize: 24, color: "#20AAB6" }} spin />
    );
    if (!load) {
      return (
        <>
          <HeaderComponent />
          <div className="content">
            <SidebarComponent />
            <div className="mainbar">
              <div className="subcontent msj">
                <Spin
                  indicator={antIcon}
                  size="large"
                  tip="Cargando..."
                  style={{ fontSize: 24, color: "#20AAB6" }}
                />
              </div>
            </div>
          </div>
        </>
      );
    } else if (pasos.contacto === null) {
      return (
        <>
          <HeaderComponent />
          <div className="content">
            <SidebarComponent />
            <div className="mainbar">
              <div className="subcontent msj">
                <h2 className="subtitle">
                  El proceso no tiene datos de los pasos
                </h2>
              </div>
            </div>
          </div>
        </>
      );
    } else
      return (
        <>
          <HeaderComponent />
          <div className="content">
            <SidebarComponent />
            <div className="mainbar">
              <h1 className="title">
                <a href="/dashboard">Prospectos Activos</a> - Detalle
              </h1>
              <div className="subcontent">
                <h2 className="subtitle">{cliente.nombre}</h2>
                <div className="dato">
                  <strong>RUC:</strong> {cliente.ruc}
                </div>
                <div className="dato">
                  <strong>Flujo operativo:</strong> {cliente.flujo_operativo}
                </div>
                <div className="detalle">
                  <div className="detalleHeader">Proceso N° {id}</div>
                  <div className="detalleBody">
                    {pasos.contacto !== null && (
                      <div className="panel">
                        <div className="panelHeader">
                          <span className="ico contacto">Contacto</span>
                        </div>
                        <div className="panelBody">
                          <div className="panelCol">
                            {pasos.contacto.telefono !== null && (
                              <>
                                <p className="label">Teléfono:</p>
                                <div className="check">{cliente.telefono}</div>
                              </>
                            )}
                          </div>
                          <div className="panelCol">
                            <p>Estado:</p>
                            {pasos.contacto.contacto_estado_n1 !== null && (
                              <div className="check">
                                {pasos.contacto.contacto_estado_n1}
                                {pasos.contacto.contacto_estado_n2 !== null && (
                                  <div className="check">
                                    {pasos.contacto.contacto_estado_n2}
                                    {pasos.contacto.contacto_estado_n3 !==
                                      null && (
                                      <div className="check">
                                        {pasos.contacto.contacto_estado_n3}
                                        {pasos.contacto.contacto_estado_n4 !==
                                          null && (
                                          <div className="check">
                                            {pasos.contacto.contacto_estado_n4}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {pasos.visita !== null && (
                      <div className="panel">
                        <div className="panelHeader">
                          <span className="ico visita">Visita</span>
                        </div>
                        <div className="panelBody">
                          <div className="panelCol">
                            {pasos.visita !== null && (
                              <>
                                {pasos.visita.nombre_contacto !== null && (
                                  <>
                                    <p>Nombre contacto:</p>
                                    <div className="check">
                                      {pasos.visita.nombre_contacto}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                            {pasos.visita.estado !== null && (
                              <>
                                <p className="label">Estado:</p>
                                <div className="check">
                                  {pasos.visita.estado}
                                </div>
                              </>
                            )}
                            {pasos.visita.fecha_visita !== null && (
                              <>
                                <p className="label">Fecha visita:</p>
                                <div className="check">
                                  {this.datetimeHumanizer(
                                    pasos.visita.fecha_visita
                                  )}
                                </div>
                              </>
                            )}
                          </div>

                          <div className="panelCol">
                            {pasos.visita.fotos !== null && (
                              <>
                                {pasos.visita.fotos.length > 0 && (
                                  <>
                                    <p>Foto del local:</p>
                                    {pasos.visita.fotos.map(
                                      (item: any, i: any) => (
                                        <p key={i}>
                                          <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={this.newurl(
                                              webservice.media(item[0])
                                            )}
                                          >
                                            Ver foto
                                          </a>
                                        </p>
                                      )
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {pasos.visita.geolocalizacion !== null &&
                              pasos.visita.geolocalizacion.longitud !==
                                null && (
                                <>
                                  <p className="label">Ubicación:</p>
                                  <p>
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={
                                        "https://www.google.com/maps/?q=" +
                                        pasos.visita.geolocalizacion.latitud +
                                        "," +
                                        pasos.visita.geolocalizacion.longitud
                                      }
                                    >
                                      Ver Geolocalización
                                    </a>
                                  </p>
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                    {pasos.califica !== null && (
                      <div className="panel">
                        <div className="panelHeader">
                          <span className="ico califica">Califica:</span>
                        </div>

                        <div className="panelBody">
                          {pasos.califica.fecha_visita !== null && (
                            <div className="panelCol">
                              <p>Fecha visita:</p>
                              <div className="check">
                                {this.datetimeHumanizer(
                                  pasos.califica.fecha_visita
                                )}
                              </div>
                            </div>
                          )}
                          <div className="panelCol">
                            <p>Estado:</p>
                            {pasos.califica.estado_n1 !== null && (
                              <div className="check">
                                {pasos.califica.estado_n1}
                                {pasos.califica.estado_n2 !== null && (
                                  <div className="check">
                                    {pasos.califica.estado_n2}
                                    {pasos.califica.estado_n3 !== null && (
                                      <div className="check">
                                        {pasos.califica.estado_n3}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {pasos.documentado !== null && (
                      <div className="panel">
                        <div className="panelHeader">
                          <span className="ico documentado">Documentado</span>
                        </div>
                        <div className="panelBody">
                          <div className="panelCol">
                            {pasos.documentado.fecha_envio && (
                              <>
                                <p>Fecha de envio:</p>
                                <div className="check">
                                  {this.datetimeHumanizer(
                                    pasos.documentado.fecha_envio
                                  )}
                                </div>
                              </>
                            )}
                            {pasos.documentado.oficina !== null && (
                              <>
                                <p className="label">Oficina:</p>
                                <div className="check">
                                  {pasos.documentado.oficina}
                                </div>
                              </>                           
                            )}
                          </div>
                          <div className="panelCol">
                            <p>Canal:</p>
                            {pasos.documentado.canal !== null && (
                              <div className="check">
                                {pasos.documentado.canal}
                              </div>
                            )}
                            {pasos.documentado.con_oficina === true ? (
                              <div className="check">
                                VISITADO GOF: SI
                              </div>
                            ):(
                              <div className="check">
                                VISITADO GOF: NO
                              </div>
                            )
                            }
                          </div>
                        </div>
                      </div>
                    )}
                    {/* NUEVO HTML RIESGO */}
                    {pasos.riesgo_gof !== null && (
                      <div className="panel">
                        <div className="panelHeader">
                          <span className="ico riesgo">Riesgos Aprobación</span>
                        </div>
                        <div className="panelBody">
                          {ofertas !== null && (
                            <div className="panelCol">
                              {ofertas.map((item: any, i: any) => (
                                <div key={i}>
                                  <p>
                                    {ofertas[i].califica === "S"
                                      ? "Si califica:"
                                      : "No califica:"}
                                  </p>
                                  <p>
                                    <span className="resaltado">
                                      {ofertas[i].tipoOferta} - {ofertas[i].monto}
                                    </span>
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}

                          <div className="panelCol">
                            {pasos.riesgo_gof.flag !== null && (
                              <div>
                                <p>Fecha {pasos.riesgo_gof.flag}:</p>
                                <div className="check">
                                  {/*Fecha {pasos.riesgo_gof.flag} -{" "}*/}
                                  {this.datetimeHumanizer(
                                    pasos.riesgo_gof.fecha_flag
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="panelCustomRow">
                              {pasos.riesgo_gof.estado !== null && (
                                <div className="panelCustomRowBlock">
                                  <p>Estado:</p>
                                  <div className="check">
                                    {pasos.riesgo_gof.estado}
                                  </div>
                                </div>
                              )}
                              {pasos.riesgo_gof.fecha_estado !== null && (
                                <div className="panelCustomRowBlock">
                                  <p>Fecha:</p>
                                  <div className="check">
                                    {this.datetimeHumanizer(
                                      pasos.riesgo_gof.fecha_estado
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            {pasos.riesgo_gof.docs !== null && (
                              //pasos.riesgo_gof.docs.map((item: any, i: any) => (
                              <div>
                                <p>Documento 1:</p>
                                <p>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={this.newurl(
                                      webservice.media(pasos.riesgo_gof.docs[0])
                                    )}
                                  >
                                    Ver Reporte de crédito
                                  </a>
                                </p>
                                {pasos.riesgo_gof.docs.length > 1 && (
                                  <>
                                    <p>Documento 2:</p>
                                    <p>
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={this.newurl(
                                          webservice.media(
                                            pasos.riesgo_gof.docs[1]
                                          )
                                        )}
                                      >
                                        Ver Declaración jurada
                                      </a>
                                    </p>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* NUEVO HTML RIESGO FIN */}
                    {pasos.desembolso !== null && (
                      <div className="panel">
                        <div className="panelHeader">
                          <span className="ico desembolso">Desembolso</span>
                        </div>
                        <div className="panelBody">
                          {pasos.desembolso.map(
                            (item: any, i: any) =>
                              pasos.desembolso[i].desembolsado !== null && (
                                <div className="panelCol" key={i}>
                                  <p>Producto:</p>
                                  <p>
                                    <span className="resaltado">
                                      {pasos.desembolso[i].tipo_oferta} -{" "}
                                      {pasos.desembolso[i].monto_producto}
                                    </span>
                                  </p>
                                  <p>Código campaña:</p>
                                  <p>
                                    <span className="resaltado">
                                      {pasos.desembolso[i].codigo_campaña}
                                    </span>
                                  </p>
                                  <p>Desembolsado:</p>
                                  <p>
                                    <span className="resaltado">
                                      {pasos.desembolso[i].desembolsado}
                                    </span>
                                  </p>
                                  {pasos.desembolso[i].fecha && (
                                    <>
                                      <p>Fecha:</p>
                                      <p>
                                        <span className="resaltado">
                                          {this.datetimeHumanizer(
                                            pasos.desembolso[i].fecha
                                          )}
                                        </span>
                                      </p>
                                    </>
                                  )}
                                  {pasos.desembolso[i].desembolsado ===
                                    "Sí, con documento" && (
                                    <>
                                      <p>Adjunto:</p>
                                      <p>
                                        <a
                                          href={pasos.desembolso[i].ruta}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Ver Documento
                                        </a>
                                      </p>
                                    </>
                                  )}
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    )}
                    {desestimar !== null && (
                      <div className="panel desestimado">
                        <div className="panelHeader">
                          <span className="ico desestimado">Desestimado</span>
                        </div>
                        <div className="panelBody">
                          <div className="panelCol">
                            <p>Desestimado por:</p>
                            {desestimar.por_n1 !== null && (
                              <div className="check">
                                {desestimar.por_n1}
                                {desestimar.por_n2 !== null && (
                                  <div className="check">
                                    {desestimar.por_n2}
                                    {desestimar.por_n3 !== null && (
                                      <div className="check">
                                        {desestimar.por_n3}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="panelCol">
                            {/*<p>No califica:</p>
                            }<div className="check">
                              Cliente BBVA
                              <div className="check">Como persona natural</div>
                            </div>*/}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <div className="panel">
                    <div className="panelHeader">
                      <span className="ico contacto">Contacto</span>
                    </div>
                    <div className="panelBody">
                      <div className="panelCol">
                        <p>Teléfono:</p>
                        <p className="check">999888777</p>
                      </div>
                      <div className="panelCol">
                        <p>Estado:</p>
                        <div className="check">Contacto Efectivo<div className="check">No califica<div className="check">Cliente BBVA<div className="check">Como persona natural</div></div></div></div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
  }
}

export default ProcesosActivosDetalle;
