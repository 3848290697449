import { combineReducers } from 'redux';
import { OPEN_MODAL, CLOSE_MODAL } from '../constants/ActionTypes';

const initialStateModal = {
    isOpen: false,
    name: '',
};

const modal = (state = initialStateModal, action: any) => {
    switch (action.type) {
      case OPEN_MODAL:
        return { isOpen: true, name: (action.name) ? action.name : '' };
      case CLOSE_MODAL:
        return { isOpen: false, name: (action.name) ? action.name : '' };
      default:
        return state;
    }
};

export default combineReducers({modal});