import * as React from 'react';
import {
  Form,
  Input,
  Modal,
  Icon
} from 'antd';
import './login.css';
import logo from '../../assets/img/logo.svg';
import * as webservice from '../api/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export class LoginComponent extends React.Component<any, any>  {

  constructor(props: any) {
    super(props);
    this.state = {
      user: {
        username: '',
        password: '',
      },
      buttonEnabled: true,
      buttonEnabled2: false,
      textBoton: 'LOGIN',
      textBoton2: 'ENVIAR',
      forgot: {
        correo: ''
      },
      visible: false
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    //console.log('Clicked cancel button');
    this.setState({
      visible: false,
    });
  };

  onChange2 = (value: any) => {
    //console.log(value.correo);
    if(value.correo !== ""){
      this.setState({
        buttonEnabled2: true,
      });
      //console.log("true");
    }else{
      this.setState({
        buttonEnabled2: false,
      });
      //console.log("false");
    }

    this.setState((state: any) => ({
      ...state,
      forgot: {
        ...state.forgot,
        ...value
      }
    }));
  }


  componentDidMount() {
    // localStorage.clear();
    //this.props.form.validateFields();
  }

  onLogin = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: any) => {
      if (!err) {
        const { user } = this.state;
        this.setState({
          textBoton: 'PROCESANDO...',
          buttonEnabled: false
        });
        webservice.login(user.username, user.password).then((res: any) => {
          const { data } = res.data;
          //console.log(res.data.error);

          if(res.data.error){
              const mensaje = JSON.stringify(res.data.error.message).replace(/\"/g, "");
            //alert(mensaje);        
              toast.error("" + mensaje, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });
              this.setState({
                textBoton: 'LOGIN',
                buttonEnabled: true
              });          
          }else{
              localStorage.setItem('auth_token', data.token);
              localStorage.setItem('con_territorio', data.con_territorio);
              localStorage.setItem('username', user.username);
              window.location.href = '/dashboard';
          }

        }).catch((err: any) => {
          //console.log(err);
          this.setState({
            textBoton: 'LOGIN',
            buttonEnabled: true
          });
        });
      }
 
    });
  };

  onRecoveryPassword = (e: any) => {
    e.preventDefault();
        const { forgot } = this.state;
        this.setState({
          textBoton2: 'PROCESANDO...',
          buttonEnabled2: false
        });
        webservice.recoverPassword(forgot.correo).then((res: any) => {
          const { data } = res.data;
          if(res.data.error){
              const mensaje = JSON.stringify(res.data.error.message).replace(/\"/g, "");
              toast.error("" + mensaje, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });      
              this.setState({
                textBoton2: 'ENVIAR',
                buttonEnabled2: true
              });
          }else{
              toast.success("Revise su correo porque se ha enviado un mensaje con su nueva clave", {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });              
              this.setState({
                textBoton2: 'CORREO ENVIADO!',
                buttonEnabled2: false
              });
              this.setState({
                visible: false,
              });
          }
        }).catch((err: any) => {
            //console.log(err);
            this.setState({
              textBoton2: 'ENVIAR',
              buttonEnabled2: true
            });
        });
  };  

  handleChange = (value: any, key: any) => {
    this.setState((state: any) => ({
      ...state,
      [key]: {
        ...state[key],
        ...value
      }
    }));
  }

  onChange = (value: any) => {
    this.setState((state: any) => ({
      ...state,
      user: {
        ...state.user,
        ...value
      }
    }));
  }

  render() {
    const { forgot, visible } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    return (
      <div className="login-wrap">

        <div className="logo-wrap">
          <img src={logo} className="logo" alt="Telcen" width="170" />
        </div>

        <div className="login-container">

          <div className="title">INGRESAR</div>
          <div className="form">
            <Form {...formItemLayout}>
              <Form.Item>
                {getFieldDecorator('username', {
                  rules: [
                    {
                      type: 'email',
                      message: 'El campo no es un correo electrónico válido',
                    },
                    {
                      required: true,
                      message: 'Ingrese un correo electrónico válido',
                    },
                  ],
                })(<Input prefix={<Icon type="user" style={{ color: 'rgba(255,255,255)', marginBottom: 5 }} />} size="large" placeholder="Usuario/E-mail" onChange={(e: any) => { this.handleChange({ username: e.target.value }, 'user'); }} />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor ingresa tu password!',
                    }
                  ],
                })(<Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(255,255,255)', marginBottom: 5 }} />} size="large" placeholder="Clave" onChange={(e: any) => { this.handleChange({ password: e.target.value }, 'user'); }} />)}
              </Form.Item>
              <div className="form-helper"><a href='# ' onClick={this.showModal}>¿Olvidaste tu contraseña?</a></div>
              <button disabled={!this.state.buttonEnabled} className="button text-center" onClick={(e: any) => this.onLogin(e)}>{this.state.textBoton}</button>
              <ToastContainer />
            </Form>
          </div>
        </div>
        <Modal
          title="OLVIDÉ MI CONTRASEÑA"
          visible={visible}
          centered
          onCancel={this.handleCancel}
          footer={[
            <button disabled={!this.state.buttonEnabled2} key="submit" type="button" className="button text-center" onClick={this.onRecoveryPassword}>{this.state.textBoton2}</button>,
          ]}
          width={350}
        >
          <div className="form-row">
            <label className="form-label">Correo electrónico</label>
            <input className="form-input" name="correo" type="email" placeholder="usuario@dominio.com" value={forgot.correo} onChange={(e: any) => { this.onChange2({ correo: e.target.value }); }} />
          </div>
          <ToastContainer />
        </Modal>
      </div>
    );
  }
}


export default Form.create({ name: 'horizontal_login' })(LoginComponent);