import * as React from "react";
import logo from "../../assets/img/logo.svg";
import "./header.css";
import { Avatar, Menu, Dropdown, Icon, Modal } from "antd";
import "antd/dist/antd.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openModal, closeModal } from "../../actions";
import { withRouter, RouteComponentProps } from "react-router";
import * as webservice from '../api/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/*
import { PwdForgottenModal } from './../login/pwdForgottenModal';
import { ResePwdModal } from './resePwdModal';
*/

//export class HeaderComponent extends Component {
class HeaderComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    //this.init();
    this.state = {
      visible: false,
      resetPass: {
        username: localStorage.getItem('username'),
        passOld: "",
        passNew: ""
      },
      textBoton: "GRABAR CAMBIOS",
      buttonEnabled: true
    };
  }

  init = () => {
    this.props.onClickOM("resetPassword");
  };

  onLogout = () => {
    window.location.reload();
  };

  handleOk = () => {
    this.setState({
      visible: false
    });
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  onChange = (value: any) => {
    this.setState((state: any) => ({
      ...state,
      resetPass: {
        ...state.resetPass,
        ...value
      }
    }));
  };



  handleMenuClick = (e: any) => {
    if (e.key === "1") {
      this.showModal();
    }
    if (e.key === "2") {
      localStorage.clear();
      window.location.href = "/";
      webservice.logout();
    }
  };


  onChangePassword = (e: any) => {
    e.preventDefault();
    if(this.state.passOld !== "" && this.state.passNew !== ""){
        const { resetPass } = this.state;
        this.setState({
          textBoton: 'PROCESANDO...',
          buttonEnabled: false
        });
        webservice.changePassword(resetPass.username, resetPass.passOld, resetPass.passNew).then((res: any) => {
          const { data } = res.data;
          if(res.data.error){
              const mensaje = JSON.stringify(res.data.error.message).replace(/\"/g, "");
              toast.error("" + mensaje, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });      
              this.setState({
                textBoton: 'GRABAR CAMBIOS',
                buttonEnabled: true
              });
          }else{
              toast.success("Su clave ha sido modificada correctamente", {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });              
              this.setState({
                textBoton: 'CLAVE MODIFICADA!',
                buttonEnabled: false
              });
              this.setState({
                visible: false,
              });
          }
        }).catch((err: any) => {
            //console.log(err);
            this.setState({
              textBoton: 'GRABAR CAMBIOS',
              buttonEnabled: true
            });
        });
    }
  };  

  /*
  renderModal = () => {
    if (this.props.nameModal === 'pwdForgotten') {
      return (
        <PwdForgottenModal
          onClickCM={this.props.onClickCM}
          isOpenModal={this.props.isOpenModal}
          onClickOM={this.props.onClickOM}
          t={this.props.t}
        />

      );
    }
    if (this.props.nameModal === 'resePassword') {
      console.log("miapp");
      return (
        <ResePwdModal
          onClickCM={this.props.onClickCM}
          isOpenModal={this.props.isOpenModal}
          onClickOM={this.props.onClickOM}
          t={this.props.t}
        />
      );
    }
    return (
      <ResePwdModal
        onClickCM={this.props.onClickCM}
        //isOpenModal={this.props.isOpenModal}
        isOpenModal={true}
        onClickOM={this.props.onClickOM}
        t={this.props.t}
      />
    );   
  }
  */

  /*
  shouldComponentUpdate(newProp: any, newState: any) {
    const { tReady } = newProp;
    if (tReady) {
      return true;
    }
    return false;
  }
  */

  render() {
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1">Actualizar contraseña</Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2">Salir</Menu.Item>
      </Menu>
    );
    //const { t, onClickOM, onClickCPO } = this.props;
    const { resetPass, visible } = this.state;
    return (
      <header className="header-component">
        {/*SI HAY TOKEN MUESTRA CABECERA*/
        (localStorage.getItem("auth_token") && (
          <>
            <div className="wrapper">
              <div className="left">
                <a href="/dashboard">
                  <img src={logo} className="logo" alt="logo" width="170" />
                </a>
              </div>
              <div className="right">
                <div className="user">
                  <Avatar
                    style={{ backgroundColor: "#20AAB6" }}
                    size="large"
                    icon="user"
                  />
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <a className="ant-dropdown-link" href="# ">
                      &nbsp;
                      <Icon
                        type="caret-down"
                        style={{ fontSize: 16, color: "#20AAB6" }}
                      />
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        )) || <>{/*} <a href="/register">Login</a>*/}</>}
        {/*this.renderModal()*/}
        <div>
          <Modal
            title="ACTUALIZAR CONTRASEÑA"
            visible={visible}
            centered
            onCancel={this.handleCancel}
            footer={[
              <button
                key="submit"
                type="button"
                className="button text-center"
                onClick={this.onChangePassword}
                disabled={!this.state.buttonEnabled}
              >
                {this.state.textBoton}
              </button>
            ]}
            width={350}
          >
            <div className="form-row">
              <label className="form-label">Contraseña actual</label>
              <input autoComplete="off" className="form-input" name="passOld" type="text" value={resetPass.passOld} onChange={(e: any) => { this.onChange({ passOld: e.target.value }); }} />
            </div>
            <div className="form-row">
              <label className="form-label">Nueva Contraseña</label>
              <input
                autoComplete="off"
                className="form-input"
                name="passNew"
                type="text"
                value={resetPass.passNew}
                onChange={(e: any) => {
                  this.onChange({ passNew: e.target.value });
                }}
              />
            </div>
          </Modal>
        </div>
      </header>      
    );
  }
}

const mapStateToProps = (state: any) => ({
  nameModal: state.header.modal.name,
  isOpenModal: state.header.modal.isOpen
});

const mapDispatchToProps = (dispatch: any) => ({
  onClickOM: bindActionCreators(openModal, dispatch),
  onClickCM: bindActionCreators(closeModal, dispatch)
});

//export default (HeaderComponent);

export default withRouter<any & RouteComponentProps<{}>, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderComponent)
);
