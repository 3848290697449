import * as React from 'react';
import './App.css';
import { MainComponent } from './components/main/main';
import { FooterComponent } from './components/footer/footer';

class App extends React.Component<any, any> {
  render() {
    return (
      <div className="App">
        <MainComponent />
        <FooterComponent />
      </div>
    );
  }
}

export default App;
