import * as React from 'react';
import './SidebarComponent.css';

export class SidebarComponent extends React.Component<any> {

  render() {
    return (
      <div className="sidebar">
        <a className="button secondary" href="/dashboard"><span className="list">PROSPECTOS ACTIVOS</span></a>
        {/*<button className="button secondary"><span className="geo">GEOLOCALIZACIÓN</span></button>*/}
      </div>
    );
  }
  
}