import * as React from 'react';
import './home.css';
import { Row, Col, Select, Table, Input } from 'antd';
import "antd/dist/antd.css";
import { SidebarComponent } from '../SidebarComponent/SidebarComponent';
import HeaderComponent from './../../components/header/header';
import * as webservice from '../api/api';
import { number } from 'prop-types';

const { Option } = Select;

class HomeComponent extends React.Component<any, any>  {

  columns = [
    {
      title: 'ID',
      dataIndex: 'nro',
      key: 'nro',
      className: 'tabla-procesos-col1',
      style: { textAlign: 'center' }
      //render: (text: any) => <a href="javascript:;">{text}</a>,
    },
    {
      title: 'CLIENTE',
      dataIndex: 'cliente',
      key: 'cliente',
      className: 'tabla-procesos-col2',
    },
    {
      title: 'RUC',
      dataIndex: 'ruc',
      key: 'ruc',
      className: 'tabla-procesos-col3',
    },
    {
      title: 'ESTADO',
      dataIndex: 'estado',
      key: 'estado',
      className: 'tabla-procesos-col4',
    },
    {
      title: 'PASO',
      dataIndex: 'paso',
      key: 'paso',
      className: 'tabla-procesos-col5',
    },
    {
      title: '',
      key: 'action',
      className: 'tabla-procesos-col6',
      render: (text: any, record: any) => (
        <a href={"/detalle/" + record.key} className="button terciary custom2">Detalle {record.name}</a>
      ),
    },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      abn: [{ id: -1, name: '--Todos--' }],
      sector: [{ id: -1, name: '--Todos--' }],
      estado: [{ id: -1, name: '--Todos--' }],
      ruc: '',
      selectAbn: -1,
      selectSector: -1,
      selectEstado: -1,
      data: [],
      loading: false,
      nroregistrospagina: number,
      //nropaginas: number,
      nroprocesos: number,
      pagecurrent: 0
    }
  }

  onChangePage = (page: any) => {
    console.log(page);
    this.setState({
      pagecurrent: page,
    });
    
    //this.loadListProcesses(this.state.selectAbn,this.state.selectSector,this.state.selectEstado,page);
   // this.loadListProcesses(this.state.ruc,this.state.selectSector,this.state.selectEstado,page);

    if (this.state.ruc===''){
      this.loadListProcesses('-1', -1, this.state.selectEstado, page); 
    }else{
      this.loadListProcesses(this.state.ruc, -1, this.state.selectEstado, page);
    }  

  };

  handleChange = (value: any) => {
    this.setState({
      ...value
    });
  }

  handleChange2 = (value: any) => {
    this.setState((state: any) => ({
      ...value
    }));
  }

  onSearch = (e: any) => {
    //const { selectAbn, selectSector, selectEstado } = this.state;
    const { ruc, selectAbn, selectEstado } = this.state;
    this.setState({
      loading: true,
      pagecurrent: 1
    });
    //this.loadListProcesses(selectSector, selectAbn, selectEstado, -1);
    if (ruc===''){
      this.loadListProcesses('-1', -1, selectEstado, -1); 
    }else{
      this.loadListProcesses(ruc, -1, selectEstado, -1);
    }    
  }

  loadListCriteria = () => {
    webservice.listCriteria().then((res: any) => {
      const { data } = res.data;
      this.setState((state: any) => ({
        abn: [
          ...state.abn,
          ...data.abn.map((item: any) => {
            return { id: item[0], name: item[1] }
          })
        ],
        sector: [
          ...state.sector,
          ...data.sector.map((item: any) => {
            return { id: item[0], name: item[1] }
          })
        ],
        estado: [
          ...state.estado,
          ...data.estado.map((item: any) => {
            return { id: item[0], name: item[1] }
          })
        ]
      }))
      console.log(this.state);
    }).catch((err: any) => {
      console.log(err);
    });
  }

  //loadListProcesses = (sector: number, abn: number, estado: number, page: number) => {
  loadListProcesses = (ruc: string, abn: number, estado: number, page: number) => {    
    this.setState({
      loading: true,      
    });
    //webservice.listProcesses(sector, abn, estado, page).then((res: any) => {
    webservice.listProcesses(ruc, abn, estado, page).then((res: any) => {      
      const { data } = res.data;
      this.setState({
        nroregistrospagina: res.data.data.length,
        nroprocesos: res.data.numero_procesos
      });
      const newData = data.map((item: any) => {
        return {
          key: item.id,
          //nro: '101',
          nro: item.id,
          cliente: item.cliente,
          ruc: item.ruc,
          estado: item.estado,
          paso: item.paso,
        }
      });
      //console.log("nroregistrospagina: "+this.state.nroregistrospagina);
      //console.log("nroprocesos: "+this.state.nroprocesos);
      console.log(res);
      this.setState({
        data: newData,
        loading: false,
      })
    }).catch((err: any) => {
      console.log(err);
    });
  }

  componentDidMount() {
    this.loadListCriteria();
    //this.loadListProcesses(-1, -1, -1, this.state.pagecurrent);
    this.loadListProcesses('-1', -1, -1, this.state.pagecurrent);
  }

  render() {
    //const { abn, sector, estado, data } = this.state;
    const { ruc, estado, data } = this.state;
    return (
      <>
        <HeaderComponent />
        <div className="content">
          <SidebarComponent />
          <div className="mainbar">
            <h1 className="title">Prospectos Activos</h1>
            <div className="subcontent">

              <div className="options">
                <Row gutter={16}>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                      <div className="labelSelect">RUC</div>
                        {/*<Input onChange={(value: any) => this.handleChange({ ruc: value })} />*/}
                        <Input onChange={(e: any) => { this.handleChange2({ ruc: e.target.value }); }} />
                    </Col>
                  {/*
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <div className="labelSelect">Sector</div>
                    <Select defaultValue="Seleccionar" style={{ width: '100%' }} onChange={(value: any) => this.handleChange({ selectSector: value })}>
                      {
                        sector.map((item: any, i: any) => (
                          <Option key={i} value={item.id}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <div className="labelSelect">ABN</div>
                    <Select defaultValue="Seleccionar" style={{ width: '100%' }} onChange={(value: any) => this.handleChange({ selectAbn: value })}>
                      {
                        abn.map((item: any, i: any) => (
                          <Option key={i} value={item.id}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Col>
                    */}
                  <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <div className="labelSelect">Estado</div>
                    <Select defaultValue="Seleccionar" style={{ width: '100%' }} onChange={(value: any) => this.handleChange({ selectEstado: value })}>
                      {
                        estado.map((item: any, i: any) => (
                          <Option key={i} value={item.id}>{item.name}</Option>
                        ))
                      }
                    </Select>
                  </Col>
                  <Col xs={24} sm={12} md={24} lg={6} xl={6}>
                    <button className="button custom1 text-center" onClick={this.onSearch}>Buscar</button>
                  </Col>
                </Row>


              </div>



              <div className="results">
                <div className="results-content">
                  <Table className={'tabla-procesos'} loading={this.state.loading} columns={this.columns} dataSource={data} bordered pagination={{ defaultCurrent: 1, current:this.state.pagecurrent, onChange:this.onChangePage, total:(this.state.nroprocesos), showTotal:(total, range) => `${range[0]}-${range[1]} de ${total} procesos`, pageSize: 10 }} />
                </div>
              </div>


            </div>

          </div>
        </div>
      </>
    )
  }
}

export default (HomeComponent);
