import * as Action from '../constants/ActionTypes';

/*
export const onChangeMessage = (value) => ({ type: Action.CHANGE_MESSAGE, value });
export const onChangeStep = (value) => ({ type: Action.CHANGE_STEP, value });
export const onChangeFormatedKey = (value) => ({ type: Action.CHANGE_FORMATTEDKEY, value });
export const onChangeToken = (value) => ({ type: Action.CHANGE_TOKEN, value });
export const onChangeUsername = (value) => ({ type: Action.CHANGE_USERNAME, value });
export const onChangePassword = (value) => ({ type: Action.CHANGE_PASSWORD, value });
export const onChangeConfirmPassword = (value) => ({ type: Action.CHANGE_CONFIRMPASSWORD, value });
export const onChangeEmail = (value) => ({ type: Action.CHANGE_EMAIL, value });
export const onChangeRedirect = (value) => ({ type: Action.CHANGE_REDIRECT, value });
export const onChangeConfirmToken = (value) => ({ type: Action.CHANGE_CONFIRMTOKEN, value });
*/
export const openModal = (name: any) => ({ type: Action.OPEN_MODAL, name });
export const closeModal = () => ({ type: Action.CLOSE_MODAL });
/*
export const openPopOver = () => ({ type: Action.OPEN_POPOVER });
export const closePopOver = () => ({ type: Action.CLOSE_POPOVER });
export const onChangeLocation = (value) => ({ type: Action.CHANGE_LOCATION, value });
export const onSetCountry = (value) => ({ type: Action.SET_COUNTRY, value });
*/

// export const getUser = () => dispatch => {
//     let username = localStorage.getItem('username');
//     let userID = localStorage.getItem('userID');
//     if (username) {
//         dispatch(onLogin(username, userID));
//     }    
// };




/*
export const afterConfirmHash = () => dispatch => {
        dispatch(openModal('login'));
};

export const afterConfirmHashRetrieve = () => dispatch => {
    dispatch(openModal('resetPassword'));
};

export const changePairSetExtra = (USDvalue, change ) => ({ type: Action.CHANGE_PAIR_SET_EXTRA, USDvalue, change });
export const changePairExtra = ( market ) => dispatch => {
    console.log('Not Implemented dispatch');
};
*/