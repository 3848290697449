export const HOST = 'https://api.mastercenter.magiadigital.com/api/banco/';
export const HOST_MEDIA = 'https://api.mastercenter.magiadigital.com';

/* API's Path */

export const LOGIN = 'login';
export const LIST_PROCESSES = 'list-processes';
export const DETAIL_PROCESSES = 'detail-process';
export const LIST_CRITERIA = 'list-criteria';
export const RECOVER_PASSWORD = 'recover-password';
export const CHANGE_PASSWORD = 'change-password';
export const LOGOUT = 'logout';