import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomeComponent from '../home/home';
import LoginComponent from '../login/login';
import ProcesosActivosDetalle from '../procesosActivosDetalle/procesosActivosDetalle';
import './main.css';
import { PrivateRoute } from '../../privateRoute';
import { Redirect } from 'react-router';
//import { PrivateRoute } from '../../privateRoute';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class MainComponent extends React.Component<any, any> {

  /*
  constructor(props: any) {
    super(props);
  }
  */

  render() {
    return (
      <main className="main-component">
        <Switch>
          <Route exact path="/" component={LoginComponent} />
          <PrivateRoute exact path="/dashboard" component={HomeComponent} />
          <PrivateRoute exact path="/detalle/:id" component={ProcesosActivosDetalle} />
          <Redirect from="/" to="/dashboard"/>
        </Switch>
        <ToastContainer />
      </main>
    )
  }

}

